@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;1,100;1,200&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;1,100;1,200&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin-top: 20px;
  background-color: #e5e5e5;
}

.App {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selfCenter {
  left: 50%;
  position: absolute;
  flex: 0 1 auto;
  transform: translateX(-50%);
}
.connectWalletCompDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 380px;
  /* height: 435px; */
  background-color: #ffffff !important;
  opacity: none !important;
  border: 1px solid #ffffff;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.14));
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 42%;
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
  -o-transform: translate(-50%, -50%); /* Opera */
  transform: translate(-50%, -50%);
  z-index: 3;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WalletBtn {
  box-sizing: border-box;
  background-image: url("/src/img/wallets/metamask.svg");
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
  width: 318px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 6px rgba(180, 185, 199, 0.25);
  border-radius: 86px;
  cursor: pointer;
  margin: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
}

.WalletBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.connectWalletLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #14161a;
  align-self: center;
}

.navBtn {
  padding: 15px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 70px;
  margin-right: 20px;
}

.myAlgoIcon {
  width: 50px;
}

.walletList .closePopup {
  position: absolute;
  z-index: 9999;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.transferBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 0px;
  width: 465px;
  height: auto;

  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
}

#footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.transferBox {
  position: relative;
  z-index: 12;
}

.wraper {
  padding: 10px 0;
  width: 420px;
  margin: 0 auto;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  /* gap: 20px; */
  height: inherit;
}

.wraperConfirm {
  padding: 10px;
  width: 420px;
  margin: 0 auto;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  gap: 15px;
}

.walletItem {
  align-items: center;
  background-color: #ffffff50;
  border: 1px solid #fff;
  border-radius: 86px;
  box-shadow: 0 1px 6px #b4b9c740;
  box-sizing: border-box;
  color: #14161a;
  text-align: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 56px;
  line-height: 160%;
  margin-bottom: 12px;
  padding: 10px 16px;
  position: relative;
  width: 100%;
}

.walletItem:hover {
  box-shadow: rgb(100 100 111 / 20%) 2px 7px 21px 2px;
}

.btnWallet {
  width: 30px;
  height: 30px;
}

.transferBoxTitle {
  /* width: 450px;
  height: 76px; */
  font-family: "Inter";
  color: #14161a;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 30px;
}

.amountLabel {
  width: 53px;
  height: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  display: flex;
  align-items: center;
  color: #14161a;
}

.flexRow {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.xpnetAmount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  color: #62718a;
}

.maxLabel {
  padding: 0px 4px;
  gap: 6px;
  width: 36px;
  height: 15px;
  background: #dee2f1;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 126%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #395feb;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.fieldBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 18px 20px;
  gap: 12px;

  width: 100%;
  height: 56px;

  background: #ececf1;
  border-radius: 12px;
  align-self: center;
}

.inputText:hover {
  background-color: #f9f9fb !important;
  border: 1px solid #395feb !important;
}

.fieldBoxError {
  background: #f1e1e3;
}

.icontext {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 126%;
}

.textXpAmount {
  background-color: transparent;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 126%;
  display: flex;
  align-items: center;
  /* color: #62718A; */
  height: inherit;
  opacity: 0.6;
  border: none;
  text-decoration: none;
  outline: none;
  color: #14161a !important;
  width: 70%;
}

.textXpAmount::-webkit-outer-spin-button,
.textXpAmount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textXpAmountError {
  color: #e31432 !important;
  opacity: 1;
}

.textXpAmount:hover
  .textXpAmount:focus
  .textDestAddress:hover
  .textDestAddress:focus
  .inputText {
  background-color: #f9f9fb !important;
  border: 1px solid #395feb !important;
}

.Divgap {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 6px;
}

.blockchain {
  width: 184px;
  align-items: center;
  justify-content: center;
}

.textDestAddress {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 126%;
  /* color: #62718A; */
  color: #14161a;
  background: none;
  border: none;
  width: inherit;
  overflow: hidden;
  height: inherit;
  outline: none;
}

.connectYourWalletBtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  width: 380px;
  height: 56px;
  background: #395feb;
  border: 1px solid #395feb;
  border-radius: 62px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.sendTranBtn:disabled {
  opacity: 0.5;
}

.secureLabel {
  align-self: center;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  color: #62718a;
  align-items: center;
  gap: 4px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gap15 {
  gap: 15px;
}
.footerlogos {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.addressError {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px 14px 24px;
  gap: 10px;
  width: 291px;
  /* height: 54px; */
  background: #252629;
  box-shadow: 0px 4px 24px rgba(180, 185, 199, 0.35);
  border-radius: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  align-items: center;
  color: #ffffff;
  align-self: center;
  order: 3;
}

.amountError {
  background: #e31432 !important;
  width: 383px;
}
.connectWalletRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: inherit;
  row-gap: 20px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.confirmTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 126%;
  display: flex;
  align-items: center;
  color: #14161a;
}

.recievingAmountLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 126%;
  display: flex;
  align-items: flex-end;
  color: #14161a;
}

.confirmTextLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
}

.xpnetValueDollar {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  display: flex;
  align-self: flex-end;
  letter-spacing: 0.03em;
  color: #62718a;
  justify-content: end;
}

.line {
  border: 1px solid #ececf1;
  width: 100%;
}

.greyBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  gap: 10px;
  background: #ececf1;
  border-radius: 9px;
}

.accountAddressLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  align-content: space-between;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  justify-items: space-between;
  color: #395feb;
}

.togglebtn {
  width: 44px;
  height: 24px;
}

.blockchainImg {
  width: 95.45px;
  height: 95.45px;
  margin-bottom: 20px;
  align-self: center;
}

.center {
  align-self: center;
}

.addressGreyDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  gap: 10px;

  width: 380px;
  height: 30px;

  background: #dee2f1;
  border-radius: 9px;
  color: #395feb;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
}

.swapBtn {
  z-index: 2;
  margin-top: 15px;
  filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.08));
  border: none;
  background: none;
  cursor: pointer;
}

.swapBtn:hover {
  content: url("/src/img/swap\ hover.svg");
}

button {
  border: none;
  background: none;
}

.background {
  background: #12151c;
  opacity: 0.5;
  width: 100vw;
  height: 120vh;
  top: 0;
  left: 0;
  position: absolute;
}

.backgroundLoaders {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.secureLabel {
  margin-bottom: 20px;
}
.mobileOnly {
  display: none;
}

.noMargin {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.copyImgIcon:hover {
  content: url("/src/img/copy/hover.svg");
  cursor: pointer;
}

.errorBackground {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(64px);
  /* backdrop-filter: blur(64px); */
  /* opacity: 0.8; */
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
}

.wraperPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1 !important;
  position: absolute;
  width: 475px;
  background: #ffffff !important;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 42%;
  -moz-transform: translate(-50%, -50%); /* Firefox */
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/
  -o-transform: translate(-50%, -50%); /* Opera */
  transform: translate(-50%, -50%);
  gap: 10px;
  backdrop-filter: unset !important;
  opacity: unset !important;
}

.wraperPopup.errorPopup {
  z-index: 1000;
}

.closeError {
  padding: 0px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 50px;
}

.mtT50 {
  margin-top: 50px;
}

.mtT32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

@media only screen and (max-width: 1520px) {
  .mtT50 {
    margin-top: 0px;
  }

  .Divgap .flexRow.mtT32 {
    margin-top: 0px !important;
  }

  .footerlogos img {
    width: 120px;
  }

  .transferBox {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 700px) {
  .footerlogos {
    display: none;
  }

  .wraperPopup {
    width: 95%;
  }
  .transferBox {
    width: 95vw;
    height: auto;
    margin-top: 86px;
    padding: 10px 0px;
  }

  .wraper {
    width: 90vw;
    padding: 0px;
    /* padding-top: 40px; */
    /* gap: 40px; */
  }

  .wraperConfirm {
    width: 90vw;
    padding: 0px;
    /* padding-top: 40px; */
    gap: 40px;
  }

  .transferBoxTitle {
    font-size: 22px;
  }
  .footerlogos {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-items: space-between;
  }
  .fieldBox {
    width: 90vw;
  }
  .connectYourWalletBtn {
    width: inherit;
  }

  .connectWalletCompDiv {
    width: 95vw;
    height: 427px;
  }

  .background {
    height: 130vh;
  }

  .Divgap {
    gap: 14px;
  }

  .mobileColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
  }

  .greyBoxMobileConfirmation {
    width: -webkit-fill-available !important;
    height: 42px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ececf1;
    border-radius: 9px;
  }
  .marginTop {
    margin: 10px 0px 10px 0px;
  }
  .mobileOnly {
    display: inline-flex;
  }
  .amountLabel {
    font-size: 16px;
  }

  .centerMobile {
    justify-content: center;
    width: inherit;
  }

  .addressError {
    width: 80vw !important;
    text-align: left;
    align-self: center;
  }

  .amountError {
    width: 80vw !important;
    text-align: left;
    align-self: center;
  }

  .btnWallet {
    align-self: center;
  }
  .mtT50 {
    margin-top: 30px;
  }
  .mtT32 {
    margin-top: 24px;
  }
  .mt40 {
    margin-top: 34px;
  }
}
