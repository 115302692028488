footer {
  margin: 40px auto 20px auto;
  width: 1024px;
  z-index: 0;
}

.footContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 16px;
  border-top: 1px solid rgba(98, 113, 138, 0.4);
}

ul.socialLInks {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 1s;
}
ul.socialLInks li {
  margin-right: 6px;
}
a.socliLink img {
  width: 36px;
}
ul.socialLInks li img:hover {
  filter: brightness(100);
}
.footRight {
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  color: #14161a;
}

.footRight a,
.footRight a:active {
  color: #62718a;
}
.powerBy img {
  display: block;
}
.twitter {
  width: 48px;
  height: 48px;
  background-image: url("../../img/footer/twitter.svg");
}
.twitter:hover {
  background-image: url("../../img/icons/twitter_hovered.svg");
}
.reddit {
  width: 48px;
  height: 48px;
  background-image: url("../../img/footer/redit.svg");
}
.reddit:hover {
  /*background-image: url("../../img/icons/reddit_hovered.svg");*/
}
.linkedin {
  width: 48px;
  height: 48px;
  background-image: url("../../img/footer/linkedin.svg");
}
.linkedin:hover {
  background-image: url("../../img/icons/linkedin_hovered.svg");
}
.telegram {
  width: 48px;
  height: 48px;
  background-image: url("../../img/footer/telegram.svg");
}
.telegram:hover {
  background-image: url("../../img/icons/telegram_hovered.svg");
}

.socliLink:hover a svg path {
  fill: #395feb;
}

@media screen and (max-width: 1024px) {
  footer {
    width: 768px;
  }
}
@media screen and (max-width: 768px) {
  footer {
    width: calc(100% - 20px) !important;
  }
  .footContainer {
    justify-content: center;
    gap: 35px;
  }
  .footRight {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .footRight a:nth-of-type(1) {
    order: 1;
  }
}
@media screen and (max-width: 425px) {
  footer {
    width: 375px;
  }
}
@media screen and (max-width: 375px) {
  footer {
    width: 346px;
  }
}
