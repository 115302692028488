@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;1,100;1,200&family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,500;1,100;1,200&family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin-top: 20px;
  background-color: #e5e5e5;
}

.App {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-left: 10%;
  padding-right: 10%;
  box-sizing: border-box;
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.selfCenter {
  left: 50%;
  position: absolute;
  flex: 0 1 auto;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.connectWalletCompDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 380px;
  /* height: 435px; */
  background-color: #ffffff !important;
  opacity: none !important;
  border: 1px solid #ffffff;
  -webkit-filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.14));
          filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.14));
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 42%; /* Firefox */ /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/ /* Opera */
  transform: translate(-50%, -50%);
  z-index: 3;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.WalletBtn {
  box-sizing: border-box;
  background-image: url(/static/media/metamask.1c596954.svg);
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 16px;
  grid-gap: 12px;
  gap: 12px;
  width: 318px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 1px 6px rgba(180, 185, 199, 0.25);
  border-radius: 86px;
  cursor: pointer;
  margin: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 160%;
}

.WalletBtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.connectWalletLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: #14161a;
  align-self: center;
}

.navBtn {
  padding: 15px;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 70px;
  margin-right: 20px;
}

.myAlgoIcon {
  width: 50px;
}

.walletList .closePopup {
  position: absolute;
  z-index: 9999;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.transferBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 0px;
  width: 465px;
  height: auto;

  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
}

#footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}

.transferBox {
  position: relative;
  z-index: 12;
}

.wraper {
  padding: 10px 0;
  width: 420px;
  margin: 0 auto;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  /* gap: 20px; */
  height: inherit;
}

.wraperConfirm {
  padding: 10px;
  width: 420px;
  margin: 0 auto;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.walletItem {
  align-items: center;
  background-color: #ffffff50;
  border: 1px solid #fff;
  border-radius: 86px;
  box-shadow: 0 1px 6px #b4b9c740;
  box-sizing: border-box;
  color: #14161a;
  text-align: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 56px;
  line-height: 160%;
  margin-bottom: 12px;
  padding: 10px 16px;
  position: relative;
  width: 100%;
}

.walletItem:hover {
  box-shadow: rgb(100 100 111 / 20%) 2px 7px 21px 2px;
}

.btnWallet {
  width: 30px;
  height: 30px;
}

.transferBoxTitle {
  /* width: 450px;
  height: 76px; */
  font-family: "Inter";
  color: #14161a;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  margin-bottom: 30px;
}

.amountLabel {
  width: 53px;
  height: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  display: flex;
  align-items: center;
  color: #14161a;
}

.flexRow {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.xpnetAmount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  color: #62718a;
}

.maxLabel {
  padding: 0px 4px;
  grid-gap: 6px;
  gap: 6px;
  width: 36px;
  height: 15px;
  background: #dee2f1;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 126%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: #395feb;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.fieldBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 18px 20px;
  grid-gap: 12px;
  gap: 12px;

  width: 100%;
  height: 56px;

  background: #ececf1;
  border-radius: 12px;
  align-self: center;
}

.inputText:hover {
  background-color: #f9f9fb !important;
  border: 1px solid #395feb !important;
}

.fieldBoxError {
  background: #f1e1e3;
}

.icontext {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 126%;
}

.textXpAmount {
  background-color: transparent;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 126%;
  display: flex;
  align-items: center;
  /* color: #62718A; */
  height: inherit;
  opacity: 0.6;
  border: none;
  text-decoration: none;
  outline: none;
  color: #14161a !important;
  width: 70%;
}

.textXpAmount::-webkit-outer-spin-button,
.textXpAmount::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.textXpAmountError {
  color: #e31432 !important;
  opacity: 1;
}

.textXpAmount:hover
  .textXpAmount:focus
  .textDestAddress:hover
  .textDestAddress:focus
  .inputText {
  background-color: #f9f9fb !important;
  border: 1px solid #395feb !important;
}

.Divgap {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  margin-top: 6px;
}

.blockchain {
  width: 184px;
  align-items: center;
  justify-content: center;
}

.textDestAddress {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 126%;
  /* color: #62718A; */
  color: #14161a;
  background: none;
  border: none;
  width: inherit;
  overflow: hidden;
  height: inherit;
  outline: none;
}

.connectYourWalletBtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 10px;
  width: 380px;
  height: 56px;
  background: #395feb;
  border: 1px solid #395feb;
  border-radius: 62px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.sendTranBtn:disabled {
  opacity: 0.5;
}

.secureLabel {
  align-self: center;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  color: #62718a;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.gap15 {
  grid-gap: 15px;
  gap: 15px;
}
.footerlogos {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.addressError {
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px 14px 24px;
  grid-gap: 10px;
  gap: 10px;
  width: 291px;
  /* height: 54px; */
  background: #252629;
  box-shadow: 0px 4px 24px rgba(180, 185, 199, 0.35);
  border-radius: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  align-items: center;
  color: #ffffff;
  align-self: center;
  order: 3;
}

.amountError {
  background: #e31432 !important;
  width: 383px;
}
.connectWalletRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: inherit;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.confirmTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 126%;
  display: flex;
  align-items: center;
  color: #14161a;
}

.recievingAmountLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 126%;
  display: flex;
  align-items: flex-end;
  color: #14161a;
}

.confirmTextLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
}

.xpnetValueDollar {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  display: flex;
  align-self: flex-end;
  letter-spacing: 0.03em;
  color: #62718a;
  justify-content: end;
}

.line {
  border: 1px solid #ececf1;
  width: 100%;
}

.greyBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px;
  grid-gap: 10px;
  gap: 10px;
  background: #ececf1;
  border-radius: 9px;
}

.accountAddressLabel {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
  align-content: space-between;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  justify-items: space-between;
  color: #395feb;
}

.togglebtn {
  width: 44px;
  height: 24px;
}

.blockchainImg {
  width: 95.45px;
  height: 95.45px;
  margin-bottom: 20px;
  align-self: center;
}

.center {
  align-self: center;
}

.addressGreyDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;
  grid-gap: 10px;
  gap: 10px;

  width: 380px;
  height: 30px;

  background: #dee2f1;
  border-radius: 9px;
  color: #395feb;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 126%;
}

.swapBtn {
  z-index: 2;
  margin-top: 15px;
  -webkit-filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.08));
          filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.08));
  border: none;
  background: none;
  cursor: pointer;
}

.swapBtn:hover {
  content: url("/static/media/swap hover.40ea6091.svg");
}

button {
  border: none;
  background: none;
}

.background {
  background: #12151c;
  opacity: 0.5;
  width: 100vw;
  height: 120vh;
  top: 0;
  left: 0;
  position: absolute;
}

.backgroundLoaders {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.secureLabel {
  margin-bottom: 20px;
}
.mobileOnly {
  display: none;
}

.noMargin {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.copyImgIcon:hover {
  content: url(/static/media/hover.a951ec8c.svg);
  cursor: pointer;
}

.errorBackground {
  background: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(64px);
  /* backdrop-filter: blur(64px); */
  /* opacity: 0.8; */
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
}

.wraperPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 1 !important;
  position: absolute;
  width: 475px;
  background: #ffffff !important;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  position: absolute;
  z-index: 999;
  left: 50%;
  top: 42%; /* Firefox */ /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/ /* Opera */
  transform: translate(-50%, -50%);
  grid-gap: 10px;
  gap: 10px;
  -webkit-backdrop-filter: unset !important;
          backdrop-filter: unset !important;
  opacity: unset !important;
}

.wraperPopup.errorPopup {
  z-index: 1000;
}

.closeError {
  padding: 0px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 50px;
}

.mtT50 {
  margin-top: 50px;
}

.mtT32 {
  margin-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

@media only screen and (max-width: 1520px) {
  .mtT50 {
    margin-top: 0px;
  }

  .Divgap .flexRow.mtT32 {
    margin-top: 0px !important;
  }

  .footerlogos img {
    width: 120px;
  }

  .transferBox {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 700px) {
  .footerlogos {
    display: none;
  }

  .wraperPopup {
    width: 95%;
  }
  .transferBox {
    width: 95vw;
    height: auto;
    margin-top: 86px;
    padding: 10px 0px;
  }

  .wraper {
    width: 90vw;
    padding: 0px;
    /* padding-top: 40px; */
    /* gap: 40px; */
  }

  .wraperConfirm {
    width: 90vw;
    padding: 0px;
    /* padding-top: 40px; */
    grid-gap: 40px;
    gap: 40px;
  }

  .transferBoxTitle {
    font-size: 22px;
  }
  .footerlogos {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-items: space-between;
  }
  .fieldBox {
    width: 90vw;
  }
  .connectYourWalletBtn {
    width: inherit;
  }

  .connectWalletCompDiv {
    width: 95vw;
    height: 427px;
  }

  .background {
    height: 130vh;
  }

  .Divgap {
    grid-gap: 14px;
    gap: 14px;
  }

  .mobileColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
  }

  .greyBoxMobileConfirmation {
    width: -webkit-fill-available !important;
    height: 42px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ececf1;
    border-radius: 9px;
  }
  .marginTop {
    margin: 10px 0px 10px 0px;
  }
  .mobileOnly {
    display: inline-flex;
  }
  .amountLabel {
    font-size: 16px;
  }

  .centerMobile {
    justify-content: center;
    width: inherit;
  }

  .addressError {
    width: 80vw !important;
    text-align: left;
    align-self: center;
  }

  .amountError {
    width: 80vw !important;
    text-align: left;
    align-self: center;
  }

  .btnWallet {
    align-self: center;
  }
  .mtT50 {
    margin-top: 30px;
  }
  .mtT32 {
    margin-top: 24px;
  }
  .mt40 {
    margin-top: 34px;
  }
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: hidden;
}

.App {
  text-align: center;
  align-items: center;
}
.connect-body-modal {
  width: 465px;
  height: 614px;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 20px;
}

.wraperPopup.errorPopup {
  padding: 20px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.wraperConfirm {
  width: 420px;
}

.transferBox {
  position: relative;
}

.transferBox.transfer,
.transferBox.confirm,
.transferBox.report {
  -webkit-animation: fadein 2s;
  animation: fadein 2s;
}

.transferBoxTitle img {
  cursor: pointer;
}

.errorPopup {
  height: unset;
}

.errorPopup .closePopup {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.errorPopup h1 {
  width: 100%;
  margin-top: 15px;
  word-break: break-all;
  line-height: 25px;
}

.errorPopup .closePopup:hover {
  -webkit-filter: brightness(111%);
          filter: brightness(111%);
}

.notifsBlock {
  position: fixed;
  left: 20px;
  top: 20px;
  width: 20%; /*media*/
}

.icontext,
.xpnetAmount,
.amountLabel {
  -webkit-filter: blur(0);
          filter: blur(0);
  transition: -webkit-filter 1.5s ease;
  transition: filter 1.5s ease;
  transition: filter 1.5s ease, -webkit-filter 1.5s ease;
}

.withLoaderContainer {
  min-width: 100px;
  height: 30px;
}

.feesContainer label {
  margin-left: auto;
}

.icontext.blury,
.xpnetAmount.blury,
.amountLabel.blury {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.notifsBlock .addressError {
  width: 100%;
  margin-top: 10px;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 2s;
}

.walletsWrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.walletItem span {
  font-size: 15px;
  font-family: Inter;
  position: relative;
  margin: 0 auto;
}

.Divgap .flexRow.mtT32 {
  margin-top: 20px;
}

.connectYourWalletBtn.mt40 {
  margin-top: 20px;
}

.blured {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 997;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.feesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.feesContainer span {
  color: #62718a;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
}

.insufficient {
  color: red;
  position: relative;
}

.failedTrx {
  display: flex;
  align-items: center;
}

.failedTrx span {
  color: rgb(255, 0, 85);
  font-size: 12px;
  margin-right: 7px;
}

.btnContainer {
  padding-top: 20px;
}

.connectYourWalletBtn {
  width: 100%;
}

.wraperConfirm .flexColumn {
  grid-gap: 25px;
}

.flexRow.fees {
  margin-top: 8px;
}

.secureLabel {
  margin: 10px auto 10px auto;
  display: flex;
  justify-content: center;
}

span.errorNotif {
  display: none;
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -19px;
  right: 0;
  font-style: italic;
  width: 120px;
  text-align: right;
}

.optInBtn {
  margin-top: 15px;
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: unset;
}

.optInBtn:hover {
  -webkit-filter: brightness(85%);
          filter: brightness(85%);
}

.reportAddres {
  width: 252px;
  flex: 0 0 auto;
  justify-content: space-around;
}

.reportAddres button {
  padding-right: 0;
}

.insufficient span.errorNotif {
  display: inline;
}

.flexRow {
  position: relative;
}

.swapBtn1 {
  position: absolute;
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.transferBox {
  padding-bottom: 15px;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-transform: scale(0.4);
          transform: scale(0.4);
}
.lds-ellipsis div {
  position: absolute;
  top: 13px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(131, 129, 129);
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}

.algoWalletList.walletsWrapper li {
  padding: 10px;
}

.modal-content {
  border: none;
  background: none;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btnWallet {
  box-shadow: none;
  transition: 0.5s ease;

  border: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a.accountAddressLabel {
  text-decoration: none;
}

.connectWalletRow {
  justify-content: flex-start;
}

.connectWalletLabel {
  margin: 0 auto;
}

.navBtn {
  padding-left: 0;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 576px) {
  html,
  #root {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .flexColumn {
    margin: auto 0;
  }

  .transferBox {
    margin-top: 0;
  }

  .transferBox {
  }

  .App {
    padding-left: 0;
    padding-right: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .wraperConfirm {
    width: 90vw;
  }

  .errorPopup,
  .wraperPopup.error {
    width: 90%;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}

/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/

.approve-modal .modal-dialog {
  /* margin-top: 30vh; */
  position: absolute;
  left: 50%;
  top: 40%; /* Firefox */ /* IE 9 */
  -webkit-transform: translate(-50%, -50%) !important; /* Safari and Chrome*/ /* Opera */
  transform: translate(-50%, -50%) !important;
  z-index: 3;
}
.approve-modal .modal-content {
  align-items: center;
}

.content {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content p {
  font-size: 16px;
}

.clip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 4px;
  gap: 4px;
  flex-direction: column;
  width: 247px;
  height: 64px;
  background-color: #395feb;
}

.clip img {
  padding-top: 8px;
  width: 60%;
  height: 60%;
}

.clip p {
  width: 90%;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

@-webkit-keyframes clipMe {
  0%,
  100% {
    clip: rect(0, 277px, 2px, 0);
  }
  25% {
    clip: rect(0, 2px, 94px, 0);
  }
  50% {
    clip: rect(88px, 277px, 90px, 0);
  }
  75% {
    clip: rect(0, 277px, 90px, 269px);
  }
}

@keyframes clipMe {
  0%,
  100% {
    clip: rect(0, 277px, 2px, 0);
  }
  25% {
    clip: rect(0, 2px, 94px, 0);
  }
  50% {
    clip: rect(88px, 277px, 90px, 0);
  }
  75% {
    clip: rect(0, 277px, 90px, 269px);
  }
}

.clip ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 140%;
  margin: -5%;
  box-shadow: inset 0 0 0 2px #395feb;
  -webkit-animation: clipMe 3s linear infinite;
          animation: clipMe 3s linear infinite;
  -webkit-animation-delay: -1.5s;
          animation-delay: -1.5s;
}

.clip ::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 140%;
  margin: -5%;
  box-shadow: inset 0 0 0 2px #395feb;
  -webkit-animation: clipMe 3s linear infinite;
          animation: clipMe 3s linear infinite;
}

.transfer-loader-modal {
  /* background-color: rgba(0, 0, 0, 0.596); */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 40px;

  position: absolute;
  width: 370px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 42%; /* Firefox */ /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari and Chrome*/ /* Opera */
  transform: translate(-50%, -50%);
  z-index: 3;
}

.transfer-loader-modal .h4 {
  font-size: 16px;
}

.transfer-loader__animation {
  padding: 60px 40px 20px 40px;
}

.transfer-loader__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 50px 20px;
  color: #14161a;
}

.transfer-loader__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  color: #14161a;
  padding-bottom: 28px;
}

.transfer-loader__text {
  max-width: 350px;
  text-align: center;
  padding-bottom: 30px;
}

.transfer-loader__sub {
  padding-bottom: 30px;
}

/* Animation */
.center {
  /* height: 5vh; */
  /* transform: scale(0.7); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.scale {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.wave {
  width: 5px;
  height: 60px;
  background: linear-gradient(180deg, #8ea5ff 0%, #125ecd 47.4%, #3e64ed 100%);
  margin: 10px;
  -webkit-animation: wave 1s linear infinite;
          animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.wave:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.wave:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.wave:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.wave:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.wave:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.wave:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.wave:nth-child(9) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.wave:nth-child(10) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@media screen and (min-width: 576px) {
  .transfer-loader-modal .modal-sm {
    max-width: 370px;
  }
}

@media screen and (max-width: 576px) {
  .transfer-loader-modal .modal-content {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

.navbar {
  padding: 0;
  width: 1024px;
  margin: 0 auto;
}
.mob-link {
  display: none !important;
}
.navbar-brand {
  padding: 0;
  margin: 0;
  display: flex;
  grid-gap: 7.61px;
  gap: 7.61px;
  cursor: pointer;
  position: relative;
}
.drop-git {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
}
.latest {
  display: flex;
  align-items: center;
  grid-gap: 7px;
  gap: 7px;
  padding: 2px 6px;
  background: #eaeaf1;
  border-radius: 30px;
}
.latest__spot {
  display: inline-flex;
  width: 6px;
  height: 6px;
  grid-gap: 5px;
  gap: 5px;
  position: relative;
  z-index: 1;
  background: #18bd1e;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.latest__spot::after {
  position: absolute;
  z-index: -1;
  content: "";
  width: 6px;
  height: 6px;
  -webkit-animation: sonarWave 2s linear infinite;
  animation: sonarWave 2s linear infinite;
  background: #18bd1e;
  border-radius: 50%;
}
@-webkit-keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes sonarWave {
  from {
    opacity: 0.4;
  }
  to {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}
.latest__date {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}
.navbar-brand .testnet {
  position: absolute;
  top: 10px;
  left: 56px;
  font-size: 12px;
  font-weight: 100;
  line-height: 140%;
  padding: 0 2px;
  background-color: #db233e;
  border-radius: 2px;
  color: white;
}
.navbar-brand div {
  margin-top: 29px;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #000000 !important;
}

.navbaroverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 12;
}

.navbar-nav {
  margin-left: auto;
  margin-top: 14px;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  align-items: center;
}
.navbar-connect-mob {
  margin-left: auto !important;
  margin-top: auto;
  margin-bottom: auto;
}
.nav-link {
  margin: 0 1px;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
  padding: 10px 16px !important;
}
.nav-link__txt {
  display: flex;
  grid-gap: 7px;
  gap: 7px;
  color: #14161a;
}
.show .nav-link {
  color: #14161a;
}
.nav-link:hover {
  background: #fff;
  border-radius: 76px;
}
.nav-link--selected {
  background: #ececf1;
}
.navbar-brand {
  background-color: unset !important;
}

.active {
  background: #fff;
  border-radius: 76px;
}
.help-center .active {
  background-color: none !important;
}
.navbar-connect {
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #14161a;
  padding: 10px 16px;
  background: #f7f7f9;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 52px;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 6px;
  box-shadow: 0px 2px 4px rgba(180, 185, 199, 0.25);
}
.navbar-connect:hover {
  background: #ffffff;
  border: 1px solid #fff;
}
.navbar-connect--connected {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  color: #62718a;
  padding: 10px 16px;
  background: #f7f7f9;
  border: 1px solid rgba(207, 207, 217, 0.9);
  box-sizing: border-box;
  border-radius: 52px;
  cursor: pointer;
  margin-left: 2px;
}
.navbar-connect-connected:hover {
  background: rgb(255, 255, 255);
  border: 1px solid #8994a8;
}
.navbar-toggler.collapsed .navbar-toggler-icon {
  background-size: contain;
  width: 2.5em;
  height: 2.5em;
}

.navbar-toggler .navbar-toggler-icon {
  background-position: center;
  width: 2.5em;
  height: 2.5em;
}

.hamburgerToggle {
  -webkit-transform: translateY(5%);
          transform: translateY(5%);
  margin-left: 7px;
}

.navbarToggleMoblie {
  display: none;
  background-image: none;
}

.navbar-toggler {
  border: none;
}
.identicon {
  width: 18px !important;
  height: 18px !important;
  margin-left: 4px;
  border-radius: 50%;
}
.navbar-dropdown .btn-primary {
  color: none !important;
  background: unset !important;
  border: unset !important;
}
.navbar-dropdown .dropdown-toggle::after {
  display: none;
}
.btn {
  padding: 0 !important;
  border: unset !important;
}
.navbar-dropdown__btn {
  margin-top: 5px;
  width: 47px;
  height: 47px;
}

.navbar-dropdown__btn:hover svg .dice {
  fill: #395feb;
}

.dropdown .navbar-dropdown__btn:active,
.navbar-dropdown__btn:focus,
.dropdown .btn-primary:focus,
.btn-primary:active {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  border-color: transparent !important;
}

.show .navbar-dropdown__btn {
  background-image: url(/static/media/burger_close.6e222da9.svg);
}
.dropdown-menu {
  left: -240px;
  border: none;
  -webkit-filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
          filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
  border-radius: 20px;
  overflow: hidden;
  padding: 0;
  width: 280px;
  font-size: 0.9rem;
  letter-spacing: 0.01rem;
  font-weight: 500;
}
.dropdown-item:nth-of-type(1) {
  padding-top: 24px;
}
.dropdown-item:last-of-type {
  padding-bottom: 24px;
}
.dropdown-item {
  padding: 15px 24px;
  transition: all 0.3s;
}
.dropdown-item:hover {
  background: #f7f7f9;
}
.drop-item {
  display: flex;
  grid-gap: 22px;
  gap: 22px;
}
.navbar-connect-mob {
  display: none;
}
.nav-about::before {
}

#Header {
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  font-family: Inter;
}

#Header nav,
.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1024px) {
  .navbar {
    width: 768px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 990px) {
  .navbar-nav {
    background: rgb(247, 247, 249);
    border-radius: 20px;
    border-bottom: 1px solid white;
    overflow: hidden;
  }
  .navbar-connect-mob {
    display: flex;
  }
  .nav-link {
    width: 100%;
    display: flex !important;
    grid-gap: 22px;
    gap: 22px;
    padding: 18px 24px !important;
    border-radius: unset !important;
  }
  .nav-link__icon {
    display: block;
  }
  .navbar-nav .navbar-connect {
    position: absolute;
  }
  .navbar-nav .navbar-dropdown {
    display: none;
  }
  .desc-link {
    display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .xdesktop_only {
    /* display: block !important; */
  }

  .xmobile_only {
    display: none !important;
    margin-left: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-connect {
    margin-left: auto;
    margin-right: 0;
  }

  .xdesktop_only {
    display: none !important;
  }

  .xmobile_only {
    /* display: block !important; */
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    width: calc(100% - 10px);
  }
}
@media screen and (max-width: 475px) {
  .navbar-collapse {
    position: absolute;
    top: 65px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 95%;
    transition: unset;
    -webkit-filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
            filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.12));
    border-radius: 20px;
    z-index: 9999;
  }

  .navbar-brand div {
    display: none;
  }

  .navbar {
    padding-right: 5px;
  }

  .navbar-toggler {
    padding-right: 0;
  }
}

@media screen and (max-width: 325px) {
  .navbar {
    width: calc(100% - 5px);
  }
}

footer {
  margin: 40px auto 20px auto;
  width: 1024px;
  z-index: 0;
}

.footContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 16px;
  border-top: 1px solid rgba(98, 113, 138, 0.4);
}

ul.socialLInks {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 1s;
}
ul.socialLInks li {
  margin-right: 6px;
}
a.socliLink img {
  width: 36px;
}
ul.socialLInks li img:hover {
  -webkit-filter: brightness(100);
          filter: brightness(100);
}
.footRight {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  color: #14161a;
}

.footRight a,
.footRight a:active {
  color: #62718a;
}
.powerBy img {
  display: block;
}
.twitter {
  width: 48px;
  height: 48px;
  background-image: url(/static/media/twitter.8e42b6cf.svg);
}
.twitter:hover {
  background-image: url(/static/media/twitter_hovered.bcf2bd46.svg);
}
.reddit {
  width: 48px;
  height: 48px;
  background-image: url(/static/media/redit.308045a0.svg);
}
.reddit:hover {
  /*background-image: url("../../img/icons/reddit_hovered.svg");*/
}
.linkedin {
  width: 48px;
  height: 48px;
  background-image: url(/static/media/linkedin.691871c4.svg);
}
.linkedin:hover {
  background-image: url(/static/media/linkedin_hovered.34bf3b2c.svg);
}
.telegram {
  width: 48px;
  height: 48px;
  background-image: url(/static/media/telegram.81e73be2.svg);
}
.telegram:hover {
  background-image: url(/static/media/telegram_hovered.56c29e49.svg);
}

.socliLink:hover a svg path {
  fill: #395feb;
}

@media screen and (max-width: 1024px) {
  footer {
    width: 768px;
  }
}
@media screen and (max-width: 768px) {
  footer {
    width: calc(100% - 20px) !important;
  }
  .footContainer {
    justify-content: center;
    grid-gap: 35px;
    gap: 35px;
  }
  .footRight {
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
  }
  .footRight a:nth-of-type(1) {
    order: 1;
  }
}
@media screen and (max-width: 425px) {
  footer {
    width: 375px;
  }
}
@media screen and (max-width: 375px) {
  footer {
    width: 346px;
  }
}

